import React from 'react'
import { Link } from 'gatsby'
import { Collapse, List } from 'antd'
import '../styles/slider.menu.sass'

const noRender = () => null

const SliderMenu = ({ open, items, bordered=false, bg }) => {
    const renderItem = (item) => {
        if (item.link) {
            return <Link to={item.link}>{item.label}</Link>
        }
        return item.render || item.label
    }
  return (
    <Collapse
      bordered={bordered}
      className="slider-menu"
          activeKey={open ? "1" : null}
          style={{background: bg}}
    >
      <Collapse.Panel key="1" header={noRender}>
        <List>
          {items.map(item => {
            const onMouseEnter = item.onMouseEnter || null
            const onMouseLeave = item.onMouseLeave || null
            return <div className="with-border" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}><List.Item className="slider-menu-label" style={item.style} key={item.key}>{renderItem(item)}</List.Item>{item.extra}</div>
          })}
        </List>
      </Collapse.Panel>
    </Collapse>
  )
}

export default SliderMenu