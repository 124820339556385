import { MenuOutlined } from "@ant-design/icons"
import { Dropdown, Layout, List, Menu, Row } from "antd"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import logoImage from "../images/magnus-logo.png"
import "../styles/header.sass"
import useBreakpoint from "../utils/useBreakpoint"
import useToggle from "../utils/useToggle"
import SliderMenu from "./slider.menu"


const { Header: AntdHeader } = Layout

const solutionMenuItems = [
  { link: "/solutions/overview", label: "overview" },
  { link: "/solutions/magnus-platform", label: "magnus platform" },
  { link: "/solutions/magnus-monitor", label: "magnus monitor" },
  { link: "/solutions/magnus-app", label: "magnus app" },
]

const industriesMenuItems = [
  { link: "/industries/oil-distribution", label: "oil distribution" },
]

const NavigationSliderMenu = ({ open }) => {
  const [isMenuOpen, toggleMenu] = useToggle()
  const [isIndustriesMenuOpen, toggleIndustriesMenu] = useToggle()

  const solutionsMenu = () => (
    <SliderMenu bg="#efefef" items={solutionMenuItems} open={isMenuOpen} />
  )

  const industriesMenu = () => (
    <SliderMenu bg="#efefef" items={industriesMenuItems} open={isIndustriesMenuOpen} />
  )

  const items = [
    { link: "/", label: "Home" },
    {
      label: "Solutions",
      render: <p onClick={toggleMenu}>Solutions</p>,
      style: isMenuOpen ? { fontWeight: "400" } : { border: "none" },
      extra: solutionsMenu(),
    },
    {
      label: "Industries",
      render: <p onClick={toggleIndustriesMenu}>Industries</p>,
      style: isIndustriesMenuOpen ? { fontWeight: "400" } : { border: "none" },
      extra: industriesMenu(),
    },
    { link: "/contact", label: "Contact" },
  ]
  const props = { items, open }
  return <SliderMenu {...props} />
}

const HeaderNavigation = () => {
  const [current, setCurrent] = React.useState(["home"])
  const handleClick = e => {
    setCurrent([e.keys])
  }

  const menu = (
    <List>
      {solutionMenuItems.map(item => (
        <List.Item className="solutions-dropdown-item" key={item.label}>
          <Link to={item.link}>{item.label}</Link>
        </List.Item>
      ))}
    </List>
  )

  const industries = (
    <List>
      {industriesMenuItems.map(item => (
        <List.Item className="industries-dropdown-item" key={item.label}>
          <Link to={item.link}>{item.label}</Link>
        </List.Item>
      ))}
    </List>
  )

  return (
    <Menu
      onClick={handleClick}
      className="navbar-nav"
      selectedKeys={current}
      mode="horizontal"
    >
      <Menu.Item key="home">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="solution">
        <Dropdown overlayClassName="solutions-dropdown" overlay={menu}>
          <Link to="/solutions/overview">Solutions</Link>
        </Dropdown>
      </Menu.Item>
      <Menu.Item key="industries">
        <Dropdown overlayClassName="industries-dropdown" overlay={industries}>
        <p>Industries</p>
        </Dropdown>
      </Menu.Item>
      <Menu.Item key="contact">
        <Link to="/contact">Contact</Link>
      </Menu.Item>
    </Menu>
  )
}

const Header = ({ siteTitle, darkHeader }) => {
  const { isSmallScreen } = useBreakpoint()
  const [isMenuOpen, toggleMenuState] = useToggle()
  const headerClasses = useMemo(() => {
    const defaultClasses = ["header"]
    if (isSmallScreen) {
      defaultClasses.push("column")
    }
    if (darkHeader) {
      defaultClasses.push('header-dark')
    }
    return defaultClasses
  }, [isSmallScreen])
  return (
    <AntdHeader className={headerClasses}>
      <Row className="inner-wrapper" justify="space-between">
        <Link to="/" className="header-logo">
          <img src={logoImage} />
          <span className="header-logo-text">MAGNUS</span>
        </Link>
        {isSmallScreen ? (
          <MenuOutlined
            className="header-menu-button"
            onClick={toggleMenuState}
          />
        ) : (
          <HeaderNavigation />
        )}
      </Row>
      {isSmallScreen ? <NavigationSliderMenu open={isMenuOpen} /> : null}
    </AntdHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
