import { Col, Layout as AntdLayout, Row, Divider } from "antd"
import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import "../styles/footer.sass"
import Img from "gatsby-image/withIEPolyfill"
import MagnusLogo from "../images/magnus-logo-white-text.svg"
import LinkedInLogo from "../images/linkedin-logo.svg"
import TwitterLogo from "../images/twitter-logo.svg"
import EnterpriseIrelandLogo from "../images/enterprise-ireland-logo.svg"
import ApprovedSupplierLogo from "../images/magnus-monitors-approved-supplier.svg"
import NDRCLogo from "../images/ndrc-logo.svg"


const navigationLinks = [
  { path: "/", label: "home" },
  { path: "/solutions/overview", label: "solutions" },
  { path: "/industries/oil-distribution", label: "industries" },
  { path: "/contact", label: "contact" },
]

const styles = {
  footerImageStyle: {
    width: '100%',
    height: '100%'
  },
  footerLogoStyle: {
    width: 150,
    marginBottom: 20,
  },
  noMargin: {
    margin: 0,
  },
}

const { Footer: AntdFooter } = AntdLayout

const Footer = () => {
  const images = useStaticQuery(graphql`
    query footerImages {
      approvedSupplier: file(
        relativePath: { eq: "magnus-monitors-approved-supplier.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      enterpriseIreland: file(
        relativePath: { eq: "enterprise-ireland-logo.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ndrc: file(relativePath: { eq: "ndrc-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <AntdFooter className="site-layout-footer">
      <Row
        gutter={[10, 25]}
        className="site-layout-footer-content"
        align="center"
        justify="space-around"
      >
        <Col
          className="site-layout-footer-content-main"
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={24}
        >
          <img style={styles.footerLogoStyle} src={MagnusLogo} />
          <p>Registered Address</p>
          <p>Unit 39, Briarhill Business Park,</p>
          <p>Ballybrit, Galway,</p>
          <p>Ireland.</p>
          <div>
            <Link className="social-media-link">
              <img src={TwitterLogo} alt="twitter-link" />
            </Link>
            <Link className="social-media-link">
              <img src={LinkedInLogo} alt="linkedin-link" />
            </Link>
          </div>
        </Col>
        <Col
          className="site-layout-footer-content-links"
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={24}
        >
          <h1>links</h1>
          {navigationLinks.map(link => (
            <Link to={link.path}>{link.label}</Link>
          ))}
        </Col>
        <Col
          className="site-layout-footer-content-contact"
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={24}
        >
          <h1>get in touch</h1>
          <div className="site-layout-footer-content-contact-info">
            <h2>general enquiries</h2>
            <p>hello@magnusmonitors.com</p>
          </div>
          <div className="site-layout-footer-content-contact-info">
            <h2>sales enquiries (Trade Only)</h2>
            <p>sales@magnusmonitors.com</p>
          </div>
        </Col>
        <Col
          className="site-layout-footer-content-extras"
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={24}
        >
          <Link to="/terms">terms&nbsp;&&nbsp;conditions</Link>
          <Link to="/privacy">cookie policy</Link>
          <Link to="/privacy">privacy policy</Link>
        </Col>
      </Row>
      <Divider style={{ opacity: 0.25 }} />
      {/* <div style={{
        display: 'flex',
        width: '100%',
      }}> */}
      <Row gutter={[10, 20]} className="footer-bottom-row" justify="space-between" align="middle">
        <Col span={8}>
          <img
            style={styles.footerImageStyle}
            src={EnterpriseIrelandLogo}
          />
        </Col>
        <Col span={8}>
          <img
            style={styles.footerImageStyle}
            src={NDRCLogo}
          />
        </Col>
        <Col span={8}>
          <img
            style={Object.assign({}, styles.footerImageStyle, styles.noMargin)}
            src={ApprovedSupplierLogo}
        />
        {/* </div> */}
        </Col>
      </Row>
    </AntdFooter>
  )
}

export default Footer
