import { useState, useCallback } from "react"

export default (initialValue = false) => {
    const [toggle, setToggleValue] = useState(initialValue)
    const toggleStateValue = useCallback((value) => {
        if (typeof value === 'boolean') {
            setToggleValue(value)
        } else {
            setToggleValue(!toggle)
        }
    }, [toggle])
    return [toggle, toggleStateValue]
}